<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span :class="btnclass">Logout</span>
        <v-icon
            :color="color"
        >
            mdi-logout
        </v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            userAdmin: 'admin/getUserAdmin',
        }),

        color () {
            if(this.userAdmin) return 'white';
            return 'primary'
        },

        btnclass () {
            if(this.userAdmin) return 'mr-2 white--text'
            return 'mr-2 primary--text'
        }
    },

    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'termReports/setProgressOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',            
        }),

        async logOut () {            
            this.setOverlay(true);
            this.mainMenu(true);
            this.enterMarks(false);
            this.editViewTermReports(false);
            this.welcomeMessage(true);
            this.setStep(1);
            await this.signOut();
            this.setExpand(true);
            this.setOverlay(false);
            //console.log("logout complete");
            if(this.userAdmin){
                this.$router.replace('/admin');
                return;
            }
            this.$router.replace('/')
        }

        
    }
}
</script>