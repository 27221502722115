<template>
    <tr>
        <td style="text-align:center">            
            <v-select
                v-model="itemModel.subject_id"                
                :items="subjects"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin" 
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.title }}
                </template>
            </v-select>
        </td>
        <td>{{ itemModel.title}}</td>
        <td style="text-align:center">
            <v-text-field
                v-model="itemModel.course_mark_max"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                disabled
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.course_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :max="itemModel.course_mark_max"
                min=0
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
                :disabled="!admin" 
            ></v-text-field>
        </td>        
        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.exam_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin || disableExamMark"
                :filled="filled" 
                max=100
                min=0
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
                
            ></v-text-field>
        </td>

        <td>
            <v-btn 
                icon
                @click="deleteRecord"
                :disabled="!admin" 
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>        
    </tr>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
        formLevel: Number,
        term: Number,
    },
    
    mounted: function () {        
        this.codedComments1 = [...this.codedComments];
        this.codedComments2 = [...this.codedComments];
        this.setDisabledFields();
    },

    data: () => ({
        subject: null,
        codedComments1: [],
        codedComments2: [],
        disableExamMark: false,
        grades: [
            { grade: '10', description: 'Excellent'},
            { grade: '9', description: 'Very Good'},
            { grade: '8', description: 'Good'},
            { grade: '7', description: 'Fair'},
            { grade: '6', description: 'Poor'},
            { grade: '5', description: 'Unsatisfactory'},
        ],   
    }),

    watch: {
        item: {
            handler () {
                this.setDisabledFields();
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters({
            subjects: 'admin/getSubjects',
            codedComments: 'termReports/getCodedComments',
            admin: 'auth/getAdmin',  
            currentTerm: 'termReports/getCurrentTerm', 
            studentRecord: 'termReports/getSelectedStudentRecord',         
        }),

        itemModel () {
            return {...this.item};
        },

        filled () {
            return this.disableExamMark
        }
        
    },    

    methods: {
        ...mapActions({
            postTable2Record: 'admin/postUpdateTable2',
            getTable2Records: 'termReports/getTable2Records',
        }),

        ...mapMutations({
            setTable2Record: 'admin/setSelectedTable2Record',           
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setSnackbar: 'admin/setSnackbar',
            setDialogDelete: 'admin/setDialogDelete',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
        }),

        setDisabledFields(){     
            if(this.currentTerm == 2 && this.formLevel <= 4){
                this.disableExamMark = true; 
            }
            else this.disableExamMark = false;
        },

        async updateRecord () { 
            // this.overlay(true);
            // this.loading(true);
            this.setSaving(true);
            this.setSaveStatus("Updating Record");
            this.setSaved(false);
            this.setSaveError(false);

            let table2Record = Object.assign({}, this.itemModel);
            table2Record.subject_id_old = this.item.subject_id;
            table2Record.subject_id_new = this.itemModel.subject_id;
            this.setTable2Record(table2Record);
            try {
                await this.postTable2Record();
                this.$emit('update-average');

                const { data } = await this.getTable2Records();
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);

                this.setSaving(false);
                this.setSaveStatus("Record Updated");
                this.setSaved(true);
                this.setSaveError(false);

                this.setSnackbar({
                    text: `Record Updated`,
                    display: true,
                    color: 'primary',
                }); 

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
                
                this.setSaving(false);
                this.setSaveStatus("Error Occured!");
                this.setSaved(false);
                this.setSaveError(true);
            }

            // this.overlay(false);
            // this.loading(false);
        },

        deleteRecord () {
            // console.log(`Delete ${this.itemModel.abbr}`);
            // console.log(this.item);
            this.setDialogDelete({
                display: true,
                text: `Delete ${this.itemModel.title} ?`,
                cancel: true,
                deleteSubject: true,
                deleteStudent: false,
                ok: false
            })            
            this.setTable2Record(this.item);
            
        },
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        width: 45px;
    }    

    ::v-deep .v-select__slot{
        height: 20px;
    }

    ::v-deep .v-input__icon{
        height: 5px;
        min-width: 8px;
        width: 8px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections{
        height: 20px;
        padding: 0px;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-btn--icon.v-size--default{
        height: 20px;
        width: 20px;
    }

    ::v-deep .v-icon.v-icon.mdi-menu-down{
        display: none;
    }

    
</style>